// icons:
import chart_icon from './icons/chart.svg';
import database_icon from './icons/database.svg';
import electronics_icon from './icons/electronics.svg';
import embedded_icon from './icons/embedded.svg';
import software_icon from './icons/software.svg';
import simulation_icon from './icons/simulations.svg';
import dv_icon from './icons/design_verifications.svg';
import production_icon from './icons/production.svg';



import tacho_icon from './icons/tacho.png';
import coins_icon from './icons/coins.png';
import handwagen_icon from './icons/handwagen.png';
import departure_icon from './icons/departure.svg';
import arrival_icon from './icons/arrival.svg';


// services page:
import services_electronics from './services/electronics.jpg';
import services_simulation from './services/simulation.jpg';
import services_embedded from './services/embedded.jpg';
import services_full_stack from './services/full_stack.jpg';
import services_layout from './services/layout.png';

// products
import vpo_hero from './products/vpo-hero.jpg';
import vpo_side from './products/vpo-side.jpg';
import vpo_front from './products/vpo-front.png';
import vpo_open_lid from './products/vpo-open_lid.jpg';
import vpo_open_lid_transparent from './products/vpo-open_lid_transparent.png';
import vpo_frames from './products/vpo-frames/*.jpg';

// edgekit
import edgekit_hero from './products/edgekit/edgekit_hero.jpg';

export default {
	chart_icon,
	database_icon,
	electronics_icon,
	embedded_icon,
	software_icon,
	tacho_icon,
	coins_icon,
	handwagen_icon,
	departure_icon,
	arrival_icon,
	simulation_icon,
	dv_icon,
	production_icon,

	services_electronics,
	services_simulation,
	services_full_stack,
	services_embedded,
	services_layout,

	vpo_hero,
	vpo_side,
	vpo_front,
	vpo_open_lid,
	vpo_open_lid_transparent,
	vpo_frames,

	edgekit_hero
};
