import $ from 'jquery';

// base64 encoded mail address "mail@pcb-arts.com"
const mail = Buffer.from('bWFpbEBwY2ItYXJ0cy5jb20=', "base64").toString();

$('.email').each(function() {
	var $target = $(this);
	// insert mail address as text
	$target.text(mail);
	// add mailto hyperlink
	if ($target.is('a')) $target.attr('href', 'mailto:' + mail);
	else $target.on('click', () => {
		// virtual link
		var link = document.createElement('a');
		link.href = 'mailto:' + mail;
		link.click();
	});
});


